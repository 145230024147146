import { CONTAINER_STYLES, FlexibleTextDisplay, IconsRowVertical, VisualHeading } from ".";
import { sentenceCaseText } from "../../Utils";
import { Heading } from "../Heading/Heading";
import { DescriptionVideo } from "./DescriptionVideo";
import { ITemplate } from "./MobileView";
import { Pill } from "./Pill";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

interface ITemplateLarge extends ITemplate {
  videoWidth: number;
}
export const TabletDesktopView = ({
  description,
  images,
  altDescription,
  video,
  tags,
  howToSection,
  whatIsSection,
  benefitsSection,
  videoWidth,
  keywords,
}: ITemplateLarge): JSX.Element => {
  const whatIsSectionHeading = sentenceCaseText(whatIsSection.heading);
  const howToSectionHeading = sentenceCaseText(howToSection.heading);
  const descriptionSectionHeading = sentenceCaseText(description.heading);
  const altDescriptionSectionHeading = sentenceCaseText(altDescription.heading);
  const benefitsSectionHeading = sentenceCaseText(benefitsSection.heading);
  return (
    <section className={twMerge(CONTAINER_STYLES, "!tw-pb-[65px] !tw-pt-[74px] min-lg:tw-flex")}>
      <div
        className="tw-flex tw-justify-center"
        style={{
          width: `${videoWidth}px`,
          maxWidth: `${videoWidth}px`,
        }}
      >
        <div className="tw-m-w-[60%]">
          <div className="tw-mb-[25px]">
            <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
              {descriptionSectionHeading}
            </Heading>
          </div>
          <FlexibleTextDisplay text={description.text} />
          <div className="tw-mb-[25px] tw-mt-[47px] ">
            <Heading headerType={3} size={"!tw-text-[24px]"} className="!tw-font-semibold tw-text-grey_800">
              {altDescriptionSectionHeading}
            </Heading>
          </div>
          <FlexibleTextDisplay text={altDescription.text} />
          {altDescription.bullets && altDescription.bullets.length > 0 && (
            <ul className="tw-list-inside tw-list-disc ">
              {altDescription.bullets.map(bullet => (
                <li key={bullet} className="tw-text-[18px]">
                  {bullet}
                </li>
              ))}
            </ul>
          )}
          <DescriptionVideo descriptionVideo={video.descriptionVideo} />
          {howToSection && (
            <>
              <div className="tw-mb-[25px] tw-mt-[47px] ">
                <Heading headerType={3} size={"!tw-text-[22px]"} className="!tw-font-semibold tw-text-grey_800">
                  {howToSectionHeading}
                </Heading>
              </div>
              {howToSection.bullets.map((bullet, index) => (
                <div key={bullet.text} className={index < howToSection.bullets.length - 1 ? `tw-mb-8` : `tw-mb-0`}>
                  <FlexibleTextDisplay
                    heading={bullet.heading}
                    text={bullet.text}
                    classNames={{
                      heading: "!tw-pb-0 !tw-text-[18px]",
                      text: index < howToSection.bullets.length - 1 ? `tw-mb-8` : `tw-mb-0`,
                    }}
                  />
                </div>
              ))}
            </>
          )}
          {whatIsSection && (
            <>
              <div className="tw-mb-[25px] tw-mt-[47px] ">
                <Heading headerType={3} size={"!tw-text-[22px]"} className="!tw-font-semibold tw-text-grey_800">
                  {whatIsSectionHeading}
                </Heading>
              </div>
              <FlexibleTextDisplay text={whatIsSection.text} />
            </>
          )}
          {benefitsSection && (
            <>
              <div className="tw-mb-[25px] tw-mt-[47px] ">
                <Heading headerType={3} size={"!tw-text-[22px]"} className="!tw-font-semibold tw-text-grey_800">
                  {benefitsSectionHeading}
                </Heading>
              </div>
              <FlexibleTextDisplay
                text={benefitsSection.text}
                classNames={{
                  text: "!tw-mb-4",
                }}
              />
            </>
          )}
        </div>
        <div className="tw-min-w-[40%] tw-max-w-[40%]">
          <div className="tw-ml-[25%]">
            {images.tileImage.map((image, index) => (
              <GatsbyImage
                key={`TabletDesktopView-tileImage-${index}`}
                image={getImage(image.src)}
                alt={image.alt}
                className={twMerge(
                  "lightBorder rounded-lg tw-w-[100%]  !tw-shadow-default",
                  index < images.tileImage.length - 1 ? "tw-mb-4" : "tw-mb-0"
                )}
              />
            ))}
            <VisualHeading heading={"template formats"} className="tw-pt-[47px] !tw-text-[18px]" />
            {tags && (
              <div className="!tw-flex tw-flex-col tw-items-start !tw-gap-4">
                {tags.map(tag => (
                  <Pill label={tag} key={tag} />
                ))}
              </div>
            )}
            <VisualHeading heading={"template features"} className="tw-pt-[47px] !tw-text-[18px]" />
            <IconsRowVertical icons={keywords} />
          </div>
        </div>
      </div>
    </section>
  );
};
