import { Enquiry } from "../Enquiry";
import { G2EasiestSetup, G2HighestUserAdoption, G2Leader } from "../SecurityBadges/SecurityBadges";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";

interface IFooterProps {
  className?: string;
}

export class Footer extends React.Component<IFooterProps, any> {
  state = { showPortal: false };

  constructor(props: IFooterProps, context: any) {
    super(props, context);
    this.openContact = this.openContact.bind(this);
  }

  openContact() {
    this.setState(() => ({ showPortal: true }));
  }

  render() {
    const footerClassName = `${this.props.className} marketing-footer`.trim();
    const portalProps = {
      type: "general-enquiry",
      subject: "general-enquiry",
      source: "marketing/footer",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    return (
      <footer className={footerClassName}>
        {this.state.showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
        <div className="container">
          <div className="row">
            <nav className="nav-footer col">
              <div className="nav-footer-inner">
                <div className="footer-links-primary">
                  <ul>
                    <li>
                      <h3>
                        <Link to="/features/">Product</Link>
                      </h3>
                    </li>
                    <li>
                      <Link to="/features/scrollytelling">Scrollytelling</Link>
                    </li>
                    <li>
                      <Link to="/features/visual-storytelling">Visual Storytelling</Link>
                    </li>
                    <li>
                      <Link to="/features/collaboration">Collaboration</Link>
                    </li>
                    <li>
                      <Link to="/features/customisation">Custom development</Link>
                    </li>
                    <li>
                      <Link to="/features/branding">Branding</Link>
                    </li>
                    <li>
                      <Link to="/features/sharing-and-analytics">Sharing & Analytics</Link>
                    </li>
                    <li>
                      <Link to="/features/publishing-and-hosting">Publishing</Link>
                    </li>
                    <li>
                      <Link to="/features/support">Support</Link>
                    </li>
                    <li>
                      <Link to="/features/security">Security</Link>
                    </li>
                    <li>
                      <Link to="/enterprise/">Enterprise</Link>
                    </li>
                    <li>
                      <Link to="/pricing/">Pricing</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <h3>
                        <Link to="/use-cases/">Use cases</Link>
                      </h3>
                    </li>
                    <li>
                      <Link to="/use-cases/annual-reports/">Annual reports</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/longform-feature-stories/">Longform feature stories</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/digital-magazines/">Digital magazines</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/data-storytelling/">Data storytelling</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/internal-communications/">Internal communications</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/educational-resources/">Educational resources</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/sports-marketing/">Sports marketing</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/science-communication/">Science communication</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/sponsored-content/">Sponsored content</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/brand-storytelling/">Brand storytelling</Link>
                    </li>
                    <li>
                      <Link to="/use-cases/white-papers/">White papers</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <h3>
                        <Link to="/industries/">Industries</Link>
                      </h3>
                    </li>
                    <li>
                      <Link to="/industries/brand-publishing">Brands</Link>
                    </li>
                    <li>
                      <Link to="/industries/media">Media</Link>
                    </li>
                    <li>
                      <Link to="/industries/corporate-comms">Corporate Comms</Link>
                    </li>
                    <li>
                      <Link to="/industries/education/">Higher Education</Link>
                    </li>
                    <li>
                      <Link to="/industries/government">Government</Link>
                    </li>
                    <li>
                      <Link to="/industries/ngo">NGOs</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <h3>Resources</h3>
                    </li>
                    <li>
                      <Link to="/case-studies/">Case studies</Link>
                    </li>
                    <li>
                      <Link to="/templates/">Templates</Link>
                    </li>
                    <li>
                      <Link to="/featured/stories/">Example stories</Link>
                    </li>
                    <li>
                      <Link to="/the-craft/">The Craft magazine</Link>
                    </li>
                    <li>
                      <Link to="/the-craft/add-the-craft-to-your-inbox/">The Craft newsletter</Link>
                    </li>
                    <li>
                      <a href="https://community.shorthand.com" target="_blank" rel="noopener noreferrer">
                        Community
                      </a>
                    </li>
                    <li>
                      <Link to="/partners/">Partners</Link>
                    </li>
                    <li>
                      <Link to="/awards/">Awards</Link>
                    </li>
                    <li>
                      <Link to="/training/">Webinars</Link>
                    </li>
                    <li>
                      <a href="https://support.shorthand.com">Help docs</a>
                    </li>
                    <li>
                      <a href="https://shorthand.com/pages/releases/index.html" target="_blank">
                        Releases
                      </a>
                    </li>
                    <li>
                      <a href="https://status.shorthand.com/">Status</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <h3>Company</h3>
                    </li>
                    <li>
                      <Link to="/about/">About Shorthand</Link>
                    </li>
                    <li>
                      <Link to="/contact/">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/careers/">Careers</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-links-secondary">
                  <div className="footer-badges d-flex flex-column align-items-center justify-content-center">
                    <div className="security-badges m-0 mb-3">
                      <a
                        href="https://cvs.babcert.com/babcert.asp?c=234513&v=53r3k1irp2"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="BAB certificate"
                      >
                        <StaticImage
                          src="../../../static/media/features/badge-iso27001.png"
                          alt="ISO27001 badge"
                          className="ml-0"
                          aria-hidden="true"
                          placeholder="blurred"
                        />
                      </a>
                      <StaticImage
                        src="../../../static/media/features/badge-soc2.png"
                        alt="SOC2 badge"
                        aria-hidden="true"
                        placeholder="blurred"
                      />
                    </div>
                    <trace-badge instance-id="41186615-dab4-45ef-aea2-06608703492e"></trace-badge>
                    <div className="security-badges !tw-mt-4 tw-gap-2">
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                      >
                        <G2Leader width={53} height={70} />
                      </a>
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                      >
                        <G2EasiestSetup width={53} height={70} />{" "}
                      </a>
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                      >
                        <G2HighestUserAdoption width={53} height={70} />
                      </a>
                    </div>
                  </div>

                  <div className="footer-social-buttons">
                    <ul className=" !tw-items-start">
                      <li>
                        <a
                          href="https://www.twitter.com/Shorthand"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="unstyled social-icon x-twitter"
                          aria-label="X Shorthand"
                        >
                          X <i className={"fa fa-brands fa-x-twitter"} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/shorthandapp"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="unstyled social-icon fb"
                          aria-label="Facebook"
                        >
                          Facebook <i className={"fa fa-brands fa-facebook"} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/shorthandapp"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Linkedin"
                          className="unstyled social-icon li"
                        >
                          LinkedIn <i className="fa fa-brands fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/shorthandco/"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Instagram"
                          className="unstyled social-icon ig"
                        >
                          Instagram <i className="fa fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.pinterest.com.au/shorthandapp/"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Pinterest"
                          className="unstyled social-icon pi"
                        >
                          Pinterest <i className="fa fa-brands fa-pinterest"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCsKU5_fUZlvzQcjKpOk_TRw"
                          target="_blank"
                          aria-label="Youtube"
                          rel="noopener noreferrer"
                          className="unstyled social-icon yt"
                        >
                          YouTube <i className="fa fa-brands fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer-links-end">
                <ul>
                  <li>
                    <Link to="/legal/customer-terms/">Terms</Link>
                  </li>
                  <li>
                    <Link to="/legal/privacy-policy/">Privacy Policy</Link>
                  </li>
                  <li>
                    <button
                      className="footer-link"
                      onClick={e => {
                        e.preventDefault();
                        // only this ts-ignore will prevent the specificty of the returned html to be lost not sure why..
                        // @ts-ignore
                        window.cookiehub.openSettings();
                      }}
                    >
                      Manage Cookies
                    </button>
                  </li>
                  <li>
                    <span className="copyright tw-text-black">
                      © Copyright {new Date().getFullYear()} Shorthand Pty Ltd. All rights reserved. Various trademarks held by their
                      respective owners.
                    </span>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    );
  }
}
