import { SectionHeading } from "../SectionHeading/SectionHeading";
import { SectionSubHeading } from "../SectionSubHeading/SectionSubHeading";
import { TemplateTile } from "./TemplateTile";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

export const MoreTemplates = ({ related }: { related: string[] }): JSX.Element => {
  const templateData = useStaticQuery(graphql`
    query RelatedQuery {
      allTemplateGalleryJson {
        nodes {
          templateId
          page
          heading
          related
          images {
            tileImage {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 869, height: 490, formats: [AUTO, WEBP, AVIF])
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const relatedContent = templateData.allTemplateGalleryJson.nodes
    .filter(item => related.includes(item.templateId))
    .sort((a, b) => related.indexOf(a.templateId) - related.indexOf(b.templateId));

  return (
    relatedContent.length > 0 && (
      <div className="text-center tw-pb-[61px]">
        <SectionHeading>More templates by Shorthand</SectionHeading>
        <SectionSubHeading>Check out these other stunning Shorthand templates</SectionSubHeading>
        <br />
        <br />
        <br />

        <div>
          <ul
            className={`tw-mb-0 tw-grid  tw-gap-5 max-md:tw-grid-cols-2 max-sm:tw-grid-cols-1  max-xxxl:tw-grid-cols-${relatedContent.length} tw-grid-cols-${relatedContent.length}`}
          >
            {relatedContent.map(item => (
              <TemplateTile item={item} key={item.page} />
            ))}
          </ul>
        </div>
      </div>
    )
  );
};
