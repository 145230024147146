import cx from "classnames";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

const FocusTrap = require("focus-trap-react");

interface EmailLinkProps {
  handleSuccess: () => void;
  handleError: (error: string) => void;
  templateData: {
    id: string;
    name: string;
    image: { src: IGatsbyImageData; alt: string };
  };

  onClose(): void;
}

const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email: string): boolean => VALID_EMAIL_REGEX.test(email);

export const EmailLink = ({ onClose, handleSuccess, handleError, templateData }: EmailLinkProps): JSX.Element | null => {
  const { id, name, image } = templateData;
  const [validEmail, setValidEmail] = React.useState<Boolean | null>(null);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [form, setForm] = React.useState({
    email: "",
    templateId: id,
    templateName: name,
  });

  const modalEl = React.useRef(null);
  const { email } = form;
  const isEmailErr = email && !validEmail;
  const isComplete = isValidEmail(email);

  // SSM parameter store needs to be updated with the correct test branch URL during development
  // or cors will block it

  // TODO swap template link url to this when we go live
  //  `${getHostname("api")}/template-link`,
  // console.log(`${getHostname("api")}/template-link`);
  async function handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    setIsProcessing(true);
    fetch("https://api.shorthand.com/template-link", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        templateId: form.templateId,
        templateName: form.templateName,
      }),
    })
      .then(response => {
        if (!response.ok) {
          handleError("Network response was not ok");
          console.error("Response:", response);
          throw new Error("Network response was not ok " + response.statusText);
        }

        setHasSubmitted(true);
        handleSuccess();
      })
      .catch(error => {
        handleError("There was an unforseen error");
        console.error("Error:", error);
      })
      .finally(() => setIsProcessing(false));
  }

  /**
   * Remove any errors from the email field while user is typing.
   * @param event
   */
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "email") {
      setValidEmail(true);
    }

    setForm({
      ...form,
      [name]: value,
    });
  }

  /*
   * Validate email when the email input loses focus. This matches the in-app
   * enquiry form behaviour.
   */
  function emailBlur(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    setValidEmail(isValidEmail(value));
  }

  React.useEffect(() => {
    function close(e: KeyboardEvent): void {
      if (e.key === "Escape") {
        onClose();
      }
    }

    window.addEventListener("keydown", close);
    document.body.style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", close);
      document.body.style.overflow = "unset";
    };
  }, []);

  React.useEffect(() => {
    if (hasSubmitted) {
      modalEl.current.focus();
    }
  }, [hasSubmitted]);

  if (!templateData.id) {
    return null;
  }

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div className="modal modal-white-bg   email-link-modal-transition">
        <div className="modal-overlay" onClick={onClose} />
        <div className="modal-dialog modal-md">
          <div
            className="modal-content modal-enquiry-form v-centered-global"
            role="dialog"
            aria-label="Reminder to open on a larger screen"
            aria-modal={true}
            tabIndex={0}
            ref={modalEl}
          >
            <div className="modal-body">
              <div className="tw-absolute tw-right-1 tw-mr-4 tw-mt-4 tw-flex tw-justify-end ">
                <button onClick={onClose}>
                  <svg width={16} height={16} fill="none">
                    <path
                      fill="#1C1C1C"
                      d="M15.17 2.422a1.125 1.125 0 0 0-1.59-1.59L8.004 6.412 2.42.828a1.125 1.125 0 0 0-1.59 1.59l5.58 5.577-5.583 5.583a1.125 1.125 0 0 0 1.59 1.59l5.577-5.579 5.583 5.583a1.125 1.125 0 0 0 1.589-1.59L9.587 8.006l5.583-5.583Z"
                    />
                  </svg>
                </button>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-pb-8 tw-pt-8 tw-text-center">
                <span className="tw-w-[80%] tw-font-CircularXXSub  tw-text-2xl tw-font-semibold tw-text-tones_darkest_grey">
                  Open on a larger screen{" "}
                </span>
                <span className="tw-w-[80%]  tw-text-lg">
                  To try this template, you&apos;ll need to open this page on a larger screen.
                </span>
                <div className="tw-mb-4 tw-w-[80%]  tw-overflow-hidden  tw-rounded-3xl">
                  <GatsbyImage image={getImage(image.src) as IGatsbyImageData} alt={image.alt} className="tw-w-[100%]" />
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#F9F9F9] tw-pb-8 tw-pt-8 tw-text-center">
                <div className="tw-w-[80%]">
                  <div className="tw-mb-[12px] tw-w-[100%] tw-text-base tw-font-bold  tw-font-bold">
                    Enter your email and we&apos;ll send you a link
                  </div>
                  <form className="!tw-m-0 tw-w-[100%] !tw-bg-[#F9F9F9] !tw-p-0">
                    <div className="form-group emailLinkForm tw-flex tw-w-[100%] tw-flex-col tw-gap-4">
                      <div>
                        <label htmlFor="email" aria-labelledby="email" />
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className={cx("form-control", {
                            "has-error": isEmailErr,
                          })}
                          autoFocus={true}
                          value={email}
                          onChange={handleChange}
                          onBlur={emailBlur}
                          placeholder="name@company.com"
                          required={true}
                        />
                        {isEmailErr && (
                          <div className="help-block" role="alert">
                            Please enter a valid email address
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-lg btn-primary !tw-m-0 tw-p-0"
                        onClick={handleSubmit}
                        disabled={!isComplete || isProcessing}
                      >
                        {isProcessing ? <span className=" tw-pt-4">Sending link</span> : <span className=" tw-pt-4">Send link</span>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
