import { sentenceCaseText } from "../Utils";
import { Brands } from "../components/Brands/Brands";
import { EmailLink } from "../components/EmailLink/EmailLInk";
import { Heading } from "../components/Heading/Heading";
import { SectionHeading } from "../components/SectionHeading/SectionHeading";
import {
  BackButton,
  BlackBg,
  COLUMN_STYLES,
  CONTAINER_STYLES,
  FULL_WIDTH_STYLES,
  HeroContent,
  MobileView,
  MoreTemplates,
  SharedLayout,
  StickyToolBar,
  TabletDesktopView,
  Testimonials,
} from "../components/Templates";
import { Callouts } from "../components/Templates/Callouts";
import { TemplatesLayout } from "../components/TemplatesLayout";
import * as Toast from "@radix-ui/react-toast";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Waypoint } from "react-waypoint";

const GalleryItemV2 = ({ data, location }) => {
  const templateData = data.templateGalleryJson;

  const [showStickyToolBar, setShowStickyToolBar] = React.useState(false);
  const [showPortal, setShowPortal] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const toastRef = React.useRef("");
  const toastTimerRef = React.useRef(0);
  const headingVisibleRef = React.useRef(true);

  const {
    templateId,
    previewId,
    name,
    heading,
    subHeading,
    images,
    video,
    description,
    altDescription,
    howToSection,
    whatIsSection,
    related,
    tags,
    keywords,
    benefitsSection,
  } = templateData;

  // control the video element intrinsic dimensions
  // prevent layout shift when loading
  const [videoWidth, setVideoWidth] = React.useState<number>(0);
  const viewportWidth = React.useRef<number>(typeof window !== `undefined` ? window.innerWidth : 0);
  const mobileMaxWidth = 620;
  const desktopWidth = 1440;
  const hdDesktopWidth = 1920;
  const largeColumnWidth = 1000;
  const xLargeColumnWidth = 1198;
  const videoWidthISset = videoWidth > 0;

  function setVideoSize(): void {
    const windowWidth = window.innerWidth;
    viewportWidth.current = window.innerWidth;
    let videoWidth;
    if (windowWidth < mobileMaxWidth) {
      videoWidth = windowWidth * 0.83;
    } else if (windowWidth < desktopWidth) {
      videoWidth = `${windowWidth * 0.64}`;
    } else if (windowWidth > desktopWidth && windowWidth < hdDesktopWidth) {
      videoWidth = `${largeColumnWidth}`;
    } else {
      videoWidth = `${xLargeColumnWidth}`;
    }
    setVideoWidth(Math.floor(videoWidth));
  }

  React.useEffect(() => {
    setVideoSize();
    const handleResize = () => setVideoSize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    return () => clearTimeout(toastTimerRef.current);
  }, [data]);

  // when the user comes back to the page / refreshes page and had already scrolled past the cta buttons
  if (typeof window !== `undefined` /** window is not defined during SSR */ && !showStickyToolBar && window.scrollY > 1000) {
    setShowStickyToolBar(true);
  }

  function handleSuccess(): void {
    toastRef.current = "Check your inbox shortly for an email from us!";
    setShowPortal(false);
    toastTimerRef.current = window.setTimeout(() => {
      setShowToast(true);
    }, 500);
  }

  function handleError(error: string): void {
    toastRef.current = error;
    setShowPortal(false);
    setShowToast(true);
  }

  function handleWaypointEnter(): void {
    setShowStickyToolBar(false);
  }

  function handeWayPointLeave(): void {
    if (headingVisibleRef.current === false) {
      setShowStickyToolBar(true);
    }
  }

  function handleHeadingEnter(): void {
    headingVisibleRef.current = true;
  }

  function handleHeadingLeave(): void {
    headingVisibleRef.current = false;
  }

  function handlePreview(): void {
    window.open(`https://preview.shorthand.com/${previewId}`, "_blank");
  }

  function goToPlayGround(): void {
    window.open(`https://app.shorthand.com/playground/template/${templateId}`, "_blank");
  }

  const sentenceCaseHeading = sentenceCaseText(heading);
  const sentenceCaseSubHeading = sentenceCaseText(subHeading);
  const sentenceCaseName = sentenceCaseText(name);

  return (
    <TemplatesLayout location={location}>
      <div
        style={{
          position: "fixed",
          zIndex: 500,
          top: "86vh",
          left: "10%",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showToast && (
          <Toast.Provider swipeDirection="right">
            <Toast.Root
              duration={5000}
              className="!tw-flex tw-w-[80%] tw-justify-center tw-rounded-md tw-bg-shorthand_teal tw-p-4 data-[swipe=cancel]:tw-translate-x-0 data-[swipe=move]:tw-translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:tw-animate-toastSlideOut data-[state=open]:tw-animate-toastSlideIn data-[swipe=end]:tw-animate-swipeOut data-[swipe=cancel]:tw-transition-[transform_200ms_ease-out]"
              open={showToast}
              onOpenChange={setShowToast}
            >
              <Toast.Title className="mb-[5px] font-medium tw-text-tw-font-CircularXXSub tw-text-center tw-text-base tw-font-semibold tw-text-white [grid-area:_title]">
                {toastRef.current}
              </Toast.Title>
            </Toast.Root>
            <Toast.Viewport className="fixed top-50 right-0 p-[10px] max-w-[100vw] m-0 list-none  z-[2147483647] tw-flex tw-w-[390px] tw-justify-center tw-outline-none [--viewport-padding:_25px]" />
          </Toast.Provider>
        )}
      </div>

      {showPortal &&
        ReactDOM.createPortal(
          <EmailLink
            onClose={() => setShowPortal(false)}
            handleSuccess={handleSuccess}
            handleError={handleError}
            templateData={{
              id: templateId,
              name: sentenceCaseName,
              image: {
                src: images.tileImage[0].src,
                alt: images.tileImage[0].alt,
              },
            }}
          />,
          document.getElementById("modal")
        )}
      <BlackBg />
      <StickyToolBar
        setShowPortal={setShowPortal}
        handlePreview={handlePreview}
        goToPlayGround={goToPlayGround}
        template={{
          id: templateId,
          heading: sentenceCaseHeading,
          name,
          image: {
            src: images.thumbImage.src,
            alt: images.thumbImage.alt,
          },
        }}
        show={showStickyToolBar}
      />

      <main className="template-page-fadein" style={{ zIndex: 2, position: "relative" }}>
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-template-landing body-light hide-elevio ",
          }}
        />

        <div className="min-xxxxl:!tw-pt-[100px] min-xl:!tw-pt-[100px]">
          <div className="z-10 tw-absolute max-xxxxl:tw-pl-[63px]  max-xxxxl:tw-pt-[125px] max-lg:tw-pl-[25px]  max-lg:tw-pt-[25px] max-sm:tw-pl-[25px] max-sm:tw-pt-[25px]  min-xxxxl:!tw-pl-[63px]">
            <BackButton />
          </div>

          <section className={CONTAINER_STYLES}>
            {/* use a waypoint to track if the heading is visible to prevent the sticky toolbar showing.*/}
            <Waypoint scrollableAncestor={null} onEnter={handleHeadingEnter} onLeave={handleHeadingLeave}>
              <div className={COLUMN_STYLES}>
                <StaticImage
                  src="../../static/media/logos/logo-white.svg"
                  alt=""
                  aria-hidden="true"
                  placeholder="blurred"
                  width={198}
                  height={150}
                  className="tw-mb-4 !tw-hidden tw-h-[65px] max-lg:!tw-block"
                  objectFit="contain"
                />

                <span className="tw-m-auto tw-mb-6 tw-w-auto tw-rounded-2xl  tw-border tw-border-grey_200 tw-bg-grey_50 tw-p-4 tw-pb-[4px] tw-pt-[4px] !tw-text-xs tw-font-bold tw-uppercase tw-uppercase !tw-text-grey_700">
                  Shorthand Templates
                </span>

                <Heading headerType={1} color="!tw-text-white">
                  {sentenceCaseHeading}
                </Heading>
                <Heading
                  headerType={2}
                  className="tw-w-full tw-pt-[18px] !tw-text-center !tw-text-2xl !tw-font-normal !tw-text-grey_scale-200 max-sm:!tw-w-full max-sm:!tw-text-xl"
                >
                  {sentenceCaseSubHeading}
                </Heading>
              </div>
            </Waypoint>
            {/* preserves space until the video dimensions get set */}
            {videoWidth ? (
              <div className="text-center justify-center tw-flex tw-flex-col">
                <div className={FULL_WIDTH_STYLES}>
                  <HeroContent
                    video={video.heroVideo}
                    videoWidth={videoWidth}
                    videoHeight={Math.floor(videoWidth * 0.562)}
                    setShowPortal={setShowPortal}
                    handlePreview={handlePreview}
                    goToPlayGround={goToPlayGround}
                    handeWayPointLeave={handeWayPointLeave}
                    handleWaypointEnter={handleWaypointEnter}
                    name={name}
                  />
                </div>
              </div>
            ) : (
              <div style={{ height: "calc(100vh)" }} />
            )}
          </section>
          {videoWidthISset ? (
            <>
              {viewportWidth.current < 1100 && (
                <MobileView
                  description={description}
                  images={images}
                  altDescription={altDescription}
                  video={video}
                  tags={tags}
                  whatIsSection={whatIsSection}
                  benefitsSection={benefitsSection}
                  howToSection={howToSection}
                />
              )}

              {viewportWidth.current > 1100 && (
                <TabletDesktopView
                  description={description}
                  images={images}
                  altDescription={altDescription}
                  video={video}
                  tags={tags}
                  whatIsSection={whatIsSection}
                  benefitsSection={benefitsSection}
                  howToSection={howToSection}
                  keywords={keywords}
                  videoWidth={videoWidth}
                />
              )}
            </>
          ) : null}
          <section className={`${CONTAINER_STYLES} tw-bg-[#f9f9f9] !tw-pt-[80px]`}>
            <div className={`${COLUMN_STYLES} min-xxxxl:!tw-w-[35%]`}>
              <MoreTemplates related={related} />
            </div>
          </section>

          <section className={`${CONTAINER_STYLES} !tw-pt-[80px]`}>
            <div className={`${COLUMN_STYLES} max-xxxxl:!tw-w-[45%] min-xxxxl:!tw-w-[35%] `}>
              <SectionHeading> You&apos;re in good company</SectionHeading>
              <div className="tw-mx-auto tw-mt-[33px] !tw-flex !tw-flex-col tw-items-center  max-sm:!tw-w-[100%]">
                <Testimonials />
              </div>
            </div>
          </section>
          <section className={`${CONTAINER_STYLES} !tw-pb-[50px] tw-pt-[50px]`}>
            <div className={`${COLUMN_STYLES} min-xxxxl:!tw-w-[35%]`}>
              <Brands />
            </div>
          </section>

          <SharedLayout />
        </div>
      </main>
    </TemplatesLayout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    templateGalleryJson(page: { eq: $slug }) {
      templateId
      previewId
      page
      name
      heading
      subHeading
      tags
      related
      video {
        descriptionVideo {
          webM {
            publicURL
          }
          mp4 {
            publicURL
          }
          poster {
            src
          }
        }
        heroVideo {
          webM {
            publicURL
          }
          mp4 {
            publicURL
          }
          poster {
            src
          }
        }
      }
      keywords {
        label
        value
      }
      description {
        heading
        text
      }
      images {
        tileImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, width: 600, formats: [AUTO, WEBP, AVIF])
            }
            publicURL
          }
        }
        thumbImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, width: 87, formats: [AUTO, WEBP, AVIF])
            }
            publicURL
          }
        }
      }
      altDescription {
        heading
        text
        bullets
      }
      howToSection {
        heading
        bullets {
          heading
          text
        }
      }
      whatIsSection {
        heading
        text
      }
      benefitsSection {
        heading
        text
      }
    }
  }
`;

export default GalleryItemV2;
