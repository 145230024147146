import { sentenceCaseText } from "../../Utils";
import { GradientOverlay } from "../ImageGradientOverlay";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

interface TemplateTile {
  item: Record<any, any>;
  theme?: "dark" | "light";
}

export const TemplateTile = ({ item, theme }: TemplateTile): JSX.Element => {
  const text = sentenceCaseText(item.heading.replace(/template/gi, ""));
  const cardTheme = theme === "dark" ? "tw-bg-white tw-bg-opacity-10 tw-text-white" : "tw-bg-white tw-text-black";
  return (
    <li className="lightBorder overflow-hidden list-item-transition tw-rounded-2xl">
      <Link className="unstyled text-center no-underline template-grid-item" to={`/templates/${item.page}`}>
        {item.images.tileImage[0].src && (
          <GradientOverlay>
            <GatsbyImage
              objectFit="contain"
              image={getImage(item.images.tileImage[0].src)}
              alt={item.images.tileImage[0].alt}
              className="tw-w-[100%]"
            />
          </GradientOverlay>
        )}
        <div
          className={twMerge(
            "tw-flex !tw-min-h-[100px] tw-items-center tw-justify-center tw-bg-white tw-p-[22px] tw-font-CircularXXSub tw-font-medium  max-xxxl:tw-text-xl  max-md:tw-text-base max-sm:tw-text-base",
            cardTheme
          )}
        >
          {text}
        </div>
      </Link>
    </li>
  );
};
